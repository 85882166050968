import { faPlusCircle, faUserPlus, faUsers, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { MemberStatuses } from "../../../Stores/Members/MemberStore";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import Modal from "../../Core/Modal";
import Paginator from "../../Core/Paginator";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import SearchBox from "../../Core/SearchBox";
import MemberActions from "./Actions/MemberActions";
import MemberDetails from "./MemberDetails";
import SortTableHeader from "../../Core/SortTableHeader";

export const MembersIndex = observer(function Index(props) {
    const session = useSessionStore();

    const [detailsModalShown, showDetailsModal] = useState(false);

    useEffect(() => {
        session.MembersStore.CRUD.GetEntries();
    }, [session.MembersStore]);

    function handleAddNewMemberClicked() {
        showDetailsModal(true);
    }

    function handleMemberAddComplete(success) {
        if(success) {
            session.MembersStore.CRUD.GetEntries();
        }

        showDetailsModal(false);
    }

	function handleFilterApply() {
		session.MembersStore.Paginator.SetPageIndex(0);
	}

	return <Panel>
			<PanelHeader icon={ faUsers } title="Members">
				<Button variant="outline-primary" onClick={ handleAddNewMemberClicked }><FontAwesomeIcon icon={ faPlusCircle }></FontAwesomeIcon> Add Member</Button>
			</PanelHeader>
			<Form className="mb-3 d-flex text-center">
				<div className="d-flex flex-row col-4 col-xl-3 pl-0">
					<SearchBox action={ handleFilterApply } placeholder="Search Members..." value={ session.MembersStore.Search } onChange={ e => session.MembersStore.Search = e.target.value }></SearchBox>
					<Button onClick={ handleFilterApply }>Apply</Button>
				</div>
				{/* <div className="w-25"></div> */}
				<div className="d-flex flex-row offset-xl-3 col-4 col-xl-3 align-items-center">
					<Form.Label className="mr-4 mb-0">Status</Form.Label>
					<Form.Control as="select" onChange={ (e) => {session.MembersStore.MemberStatusId = e.target.value; handleFilterApply();}}>
						<option value={ 0 }>{ "Select Status to filter" }</option>
						<option value={ 1 }>{ MemberStatuses.GetMemberStatusText(1) }</option>
						<option value={ 3 }>{ MemberStatuses.GetMemberStatusText(3) }</option>
						<option value={ 4 }>{ MemberStatuses.GetMemberStatusText(4) }</option>
					</Form.Control>
				</div>
				<div className="d-flex flex-row text-nowrap col-4 col-xl-3 pr-0 align-items-center">
					<Form.Label className="mr-4 mb-0">PWA Registered</Form.Label>
					<Form.Control as="select" onChange={ (e) => {session.MembersStore.PWARegistered = e.target.value; handleFilterApply();}}>
						<option value={ 0 }>{ "Select" }</option>
						<option value={ 1 }>{ "Yes" }</option>
						<option value={ 2 }>{ "No" }</option>
					</Form.Control>
				</div>
			</Form>
			<Row>
				<Col lg={12}>
					<Table striped bordered hover>
						<thead>
							<tr>
								<SortTableHeader
									lable="Name"
									sortBy="Name"
									storeSortBy={ session.MembersStore.SortBy }
									storeSortByDescending={ session.MembersStore.SortByDescending }
									onClick={ e => {
										session.MembersStore.SortBy = "Name";
										session.MembersStore.SortByDescending = !session.MembersStore.SortByDescending;
										handleFilterApply();
									}}
								/>	
								<SortTableHeader
									lable="Service Number (SNO)"
									sortBy="ServiceNumber"
									storeSortBy={ session.MembersStore.SortBy }
									storeSortByDescending={ session.MembersStore.SortByDescending }
									onClick={ e => {
										session.MembersStore.SortBy = "ServiceNumber";
										session.MembersStore.SortByDescending = !session.MembersStore.SortByDescending;
										handleFilterApply();
									}}
								/>
								<th>Department</th>
								<th>Status</th>
								<th>PWA Registered</th>
								<th className="text-center">Actions</th>
							</tr>
						</thead>
						{
							session.MembersStore.CRUD.GettingEntries === false &&
							<tbody>
							{
								session.MembersStore.CRUD.Entries.length > 0 &&
								session.MembersStore.CRUD.Entries.map((member) =>
								<tr key={ member.Id }>
									<td>{ member.Name }</td>
									<td>{ member.ServiceNumber }</td>
									<td>{ member.Department }</td>
									<td>{ MemberStatuses.GetMemberStatusText(member.MemberStatusId) }</td>
									<td>{ member.RegisteredOnPWA ? "Yes" : "No" }</td>
									<td className="align-middle">
										<MemberActions member={ member }></MemberActions>
									</td>
								</tr>
								)
							}

							{
								session.MembersStore.CRUD.Entries.length === 0 &&
								<tr className="text-center">
									<td colSpan="5"><b className="text-muted">No members returned</b></td>
								</tr>
							}
							</tbody>
						}
					</Table>
					{
						session.MembersStore.CRUD.GettingEntries === false && session.MembersStore.CRUD.Entries.length > 0 &&
						<Paginator paginator={ session.MembersStore.Paginator }></Paginator>
					}
					<BusyIndicator text="Retrieving Members..." show={ session.MembersStore.CRUD.GettingEntries }></BusyIndicator>
				</Col>
			</Row>
			<Modal size="lg" show={ detailsModalShown } title="Add New Member" icon={ faUserPlus } onHide={ e => handleMemberAddComplete(false)}>
				<MemberDetails onEditComplete={ handleMemberAddComplete }></MemberDetails>
			</Modal>
		</Panel>
});

export default MembersIndex;