import { Dropdown, DropdownButton, Navbar } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import logo from "../../Images/logo-small.png"
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

export var Topbar = observer(function Topbar() {
    var session = useSessionStore();

    function handleSignOutClicked() {
        session.Logout();
    }

    return  <Navbar className="bg-primary" style={{ marginLeft: "-15px", marginRight: "-15px" }}>
                <Navbar.Brand className="mr-auto">
                    <b className="text-white ml-2">Durban Municipal Thrift Fund</b>
                </Navbar.Brand>
                {
                    session.UserIsLoggedIn &&
                    <DropdownButton title={ `${session.Session.User.FirstName} ${session.Session.User.LastName}` } align="end">
                        <Dropdown.Item onClick={ handleSignOutClicked }><FontAwesomeIcon icon={ faSignOutAlt }></FontAwesomeIcon> Sign Out</Dropdown.Item>
                    </DropdownButton>
                }
            </Navbar>
});

export default Topbar;